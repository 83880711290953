export class Time {
	hour?: number
	minute?: number
	timePeriod?: 'AM' | 'PM'
	format: '12' | '24' = '24'

	private _template = 'x : y z'

	get value() {
		const result = this._template
			.replace('x', this.hour !== undefined && !isNaN(this.hour) ? this.hourAsString() : '__')
			.replace('y', this.minute !== undefined && !isNaN(this.minute) ? this.minuteAsString() : '__')
		if (this.format === '24') {
			return result.replace(' z', '')
		} else {
			return result.replace('z', this.timePeriod ?? '__')
		}
	}

	get valid() {
		return this.hour !== undefined && this.minute !== undefined
	}

	/**
	 * Converts the current time object to a string representation in the format 'HH:MM'.
	 *
	 * @return {string} The string representation of the time object in the format 'HH:MM'.
	 */
	toJSON(): string {
		return this.value
	}

	/**
	 * Returns the hour as a string with a leading zero if necessary.
	 *
	 * @return {string} The hour as a string with a leading zero if necessary.
	 */
	hourAsString(): string {
		return this._paddingZero(this.hour)
	}

	/**
	 * Converts the minute to a string representation with padding zero if necessary.
	 *
	 * @returns {string} The minute as a string.
	 */
	minuteAsString(): string {
		return this._paddingZero(this.minute)
	}

	/**
	 * @private
	 * Adds padding zero to a given number if it is less than 10.
	 *
	 * @param {number} [number] - The number to be padded with zeros.
	 *
	 * @returns {string} - The padded number as a string.
	 */
	private _paddingZero(number?: number): string {
		if (number) {
			if (number < 10) {
				return `0${number}`
			} else return `${number}`
		} else return '00'
	}
}
